<style>
a.type-active {
  background-color: #e1ebf3;
}
</style>

<template>
    <div id="select-lang" class="dropdown mt-2" :class="{'px-2 bg-white border-radius-sm' : ['Signin', 'ResetPass'].includes(pageName)}" style="">
        <a href="javascript:void(0);" class="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            <img :src="publicPath + 'img/th.png'" alt="th" class="pr-3" v-show="Global.locale == 'th'">
            <img :src="publicPath + 'img/en.png'" alt="en" class="pr-3" v-show="Global.locale == 'en'">
            <img :src="publicPath + 'img/zh.png'" alt="zh" class="pr-3" v-show="Global.locale == 'zh'">
            <span class="ms-1">
            <svg fill="#ff5011" height="10" width="10" viewBox="0 0 24 24" role="img" class="mt-1"><path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path></svg>
            </span>
        </a>
        <ul class="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton" >
            <li class="dropdown-item">
            <a href="javascript:void(0)" data-selenium-name="language" data-selenium-value="th" class="text-left d-block" v-bind:class="[Global.locale == 'th' ? 'type-active' : '']" @click="Global.locale = 'th'"><img :src="publicPath + 'img/th.png'" alt="th"> ไทย</a>
            </li>
            <li class="dropdown-item">
            <a href="javascript:void(0)" data-selenium-name="language" data-selenium-value="en" class="text-left d-block" v-bind:class="[Global.locale == 'en' ? 'type-active' : '']" @click="Global.locale = 'en'"><img :src="publicPath + 'img/en.png'" alt="en"> Eng</a>
            </li>
            <li class="dropdown-item">
            <a href="javascript:void(0)" data-selenium-name="language" data-selenium-value="zh" class="text-left d-block" v-bind:class="[Global.locale == 'zh' ? 'type-active' : '']" @click="Global.locale = 'zh'"><img :src="publicPath + 'img/zh.png'" alt="zh"> 简体中文 </a>
            </li>
        </ul>
    </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";

export default {
    name: "SelectLang",
    data: function(){
        return {
            Global,
            publicPath: process.env.BASE_URL
        }
    },
    props: {
      pageName: String
    }
}
</script>

