export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pdpaTexts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This website uses cookies to improve performance and collect analytics. By continuing to use the website, you accept our ", _interpolate(_list(0))])},
        "pdpaPrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "pdpaAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])}
      },
      "th": {
        "pdpaTexts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["เว็บไซต์นี้ใช้คุกกี้ในการเก็บข้อมูลเพื่อพัฒนาประสิทธิภาพการใช้งานและเพื่อเก็บข้อมูลสถิติ เมื่อคุณใช้เว็บไซต์ต่อไปถือว่าคุณยอมรับ", _interpolate(_list(0)), "ของเรา"])},
        "pdpaPrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นโยบายความเป็นส่วนตัว"])},
        "pdpaAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับทราบ"])}
      },
      "zh": {
        "pdpaTexts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This website uses cookies to improve performance and collect analytics. By continuing to use the website, you accept our ", _interpolate(_list(0))])},
        "pdpaPrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "pdpaAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])}
      }
    }
  })
}
