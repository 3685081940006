<style>

  .navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0 1rem;
  }

  .wrapper {
      position: relative;
      cursor: not-allowed; 
  }
  .wrapper a {
      pointer-events: none;
      color: grey !important;
  }

  #main2area footer.fixedBottom{
    position: fixed;
    left: 250px;
    bottom: 5px;
    width: calc(100% - 250px);
  }

  #main2area.hide2pk footer.fixedBottom{
    position: fixed;
    left: 30px;
    bottom: 5px;
    width: calc(100% - 30px);
  }

  #main2area footer.signinBottom{
    display: block;
  }

  @media (min-height: 800px) {
    #main2area footer.signinBottom{
      position: fixed;
      left: 0px;
      bottom: 5px;
      width:100%;
    }
  }

  @media (max-height: 750px) {
    #hide2menu {
      bottom: 1.5rem;
    }

    #show2menu{
      bottom: 1.5rem;
    }
  }

#topLoading{
    position: fixed;
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 4000;
    background-color: rgba(250,251,252,.62);
    overflow: hidden;
    margin: auto;
    top: 0;
    left: 0;
}

#topLoading .spinner-container{
  display: inline-block;
  position: fixed;
  left: 47%;
  top: 40%;
  width: 80px;
  height: 80px;
  text-align: center!important;
}

#errorModal .modal-footer{
  border-top: 0;
  padding: 0;
  margin: 0;
}

#errorModal .modal-footer .btn, #errorModal .modal-footer .btn:hover{
  width: 100%;
  background: #ff5011;
  margin: 0;
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
  padding: 1.2rem 0;
  transform: none;
}

#errorModal .text-orange{
  color:#ff5011 !important;
}

#pdpa_cookie {
  position: fixed;
  bottom: 10px;
  width: 100%;
  padding: 0px 5px;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  transform: scale(.95);
  transition: transform .2s ease,opacity .2s ease,visibility .2s ease!important;
  min-height: 77px;
  text-align: center;
}

#pdpa_cookie.resultPackage{
  bottom:105px !important;
}

#pdpa_cookie.pdpa_show {
  opacity: 1;
  visibility: visible!important;
  transform: scale(1);
}

#pdpa_cookie #pdpa_section {
    display: block;
    background: #EFF8FF;
    border-radius: 16px;
    border: 1px solid #0071D9;
    padding: 16px;
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
}


#pdpa_cookie #pdpa_section #pdpa_text {
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: #43464c;
    line-height: 1.5;
}

#pdpa_cookie #pdpa_section #pdpa_text a {
    color: #ff5011;
    text-decoration: none;
    background-color: transparent;
    transition: color .15s ease-in-out;
}

#pdpa_cookie #pdpa_section #pdpa_btn #pdpa_accept_all {
  padding: 4px 16px;
  color: #fff;
  background-color: #196bb6;
  border-color: #196bb6;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 400;
}
</style>

<template>
  <div id="app">
    <div >
      <!-- Sidebar -->
      <aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3  bg-white" id="sidenav-main" data-color="primary" v-if="$route.name !== 'SignIn' && Global.jwt !== ''">
        <div class="sidenav-header">
          <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
          <router-link to="/home" class="navbar-brand m-0">
            <img :src="publicPath + 'img/roojai-logo.svg'" class="navbar-brand-img h-100" alt="Rojai_logo">
            <span class="ms-1 font-weight-bold">ROOJAI</span>
          </router-link>
        </div>
        <hr class="horizontal dark mt-0">
        <div class="collapse navbar-collapse  w-auto  max-height-vh-100" id="sidenav-collapse-main">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/home" class="nav-link" v-bind:class="{ active: this.$route.path === '/home' }" data-selenium-name="menu-home">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"  viewBox="0 0 48 48"><path d="M8 42V21.95l-4.2 3.2-1.8-2.4 6-4.55v-5.7h3v3.4L24 6l22 16.8-1.8 2.35-4.2-3.2V42Zm3-3h11.5v-8h3v8H37V19.65l-13-9.9-13 9.9ZM8 10q0-2.3 1.625-3.9T13.5 4.5q1.05 0 1.775-.75Q16 3 16 2h3q0 2.25-1.6 3.875T13.5 7.5q-1 0-1.75.725T11 10Zm3 29h26-26Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.home")}}</span>
              </router-link>
            </li>
            <li class="nav-item" v-bind:class="{'wrapper' : Global.authority === 'ADMIN'}">
              <router-link :to="Global.brokerDetails.accountSource !== 'Affiliate' ? '/dashboard' : '/dashboard-aff'" class="nav-link " v-bind:class="{ active: this.$route.path === '/dashboard' || this.$route.path === '/dashboard-aff'}" data-selenium-name="menu-dashboard">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><path d="M25.5 19.5V6H42v13.5ZM6 25.5V6h16.5v19.5ZM25.5 42V22.5H42V42ZM6 42V28.5h16.5V42Zm3-19.5h10.5V9H9ZM28.5 39H39V25.5H28.5Zm0-22.5H39V9H28.5ZM9 39h10.5v-7.5H9Zm10.5-16.5Zm9-6Zm0 9Zm-9 6Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.dashboard")}}</span>
              </router-link>
            </li>
            <li class="nav-item mt-3">
              <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">{{$t("menu.report")}}</h6>
            </li>
            <li class="nav-item">
              <router-link :to="Global.brokerDetails.accountSource !== 'Affiliate' ? '/opportunity-report' : '/opportunity-aff-report'" class="nav-link" v-bind:class="{ active: this.$route.path === '/opportunity-report' || this.$route.path === '/opportunity-aff-report'}" data-selenium-name="menu-opportunity-report">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><path d="M6.15 36 4 33.85 18.6 19.3l8.35 8.35L39 15.6h-6.35v-3H44v11.35h-2.95V17.8L26.9 31.95l-8.35-8.35Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.opportunityReport")}}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
                <router-link to="/active-policy-report" class="nav-link" v-bind:class="{ active: this.$route.path === '/active-policy-report' }" data-selenium-name="menu-sales-policy-report">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m436 709 228-228-42-41-183 183-101-101-44 44 142 143Zm44 266q-140-35-230-162.5T160 533V295l320-120 320 120v238q0 152-90 279.5T480 975Zm0-62q115-38 187.5-143.5T740 533V337l-260-98-260 98v196q0 131 72.5 236.5T480 913Zm0-337Z"/></svg>			
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.activepolicyReport")}}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
              <router-link to="/renewal-report" class="nav-link" v-bind:class="{ active: this.$route.path === '/renewal-report' }" data-selenium-name="menu-renewal-report">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><path d="M19.95 35.65v-7H17V21.5q0-1.05 1.425-1.7 1.425-.65 3.725-.65t3.725.65q1.425.65 1.425 1.7v7.15h-2.95v7Zm2.2-17.95q-1.15 0-1.925-.775-.775-.775-.775-1.925t.775-1.925Q21 12.3 22.15 12.3t1.925.775q.775.775.775 1.925t-.775 1.925q-.775.775-1.925.775Zm0 24.5q-7.6 0-12.9-5.3-5.3-5.3-5.3-12.9 0-3.8 1.425-7.1t3.9-5.775q2.475-2.475 5.775-3.9 3.3-1.425 7.1-1.425t7.1 1.425q3.3 1.425 5.775 3.9 2.475 2.475 3.9 5.775 1.425 3.3 1.425 7.1v.9l3.5-3.5 2.1 2.1-7.1 7.1-7.1-7.1 2.1-2.1 3.5 3.5V24q0-6.25-4.475-10.725T22.15 8.8q-6.25 0-10.725 4.475T6.95 24q0 6.25 4.475 10.725T22.15 39.2q2.85 0 5.525-1.075Q30.35 37.05 32.35 35.2l2.15 2.15q-2.4 2.25-5.65 3.55-3.25 1.3-6.7 1.3Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.renewalReport")}}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
              <router-link to="/inspection-report" class="nav-link" v-bind:class="{ active: this.$route.path === '/inspection-report' }" data-selenium-name="menu-inspection-report">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><path d="m24 11.7-5.3-5.25 2.1-2.15L24 7.5 31.5 0l2.1 2.1ZM7.5 48q-.65 0-1.075-.425Q6 47.15 6 46.5V30.3l4.25-12.8q.25-.7.825-1.1.575-.4 1.325-.4h23.2q.75 0 1.325.4t.825 1.1L42 30.3v16.2q0 .65-.425 1.075Q41.15 48 40.5 48h-1.05q-.65 0-1.05-.425T38 46.5v-2.7H10v2.7q0 .65-.425 1.075Q9.15 48 8.5 48Zm2.65-20.7h27.7L35.1 19H12.9ZM9 30.3v10.5Zm5.3 8q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.975-.775-.825-1.925-.825t-1.975.825q-.825.825-.825 1.975 0 1.15.825 1.925.825.775 1.975.775Zm19.45 0q1.15 0 1.975-.8.825-.8.825-1.9 0-1.15-.825-1.975-.825-.825-1.975-.825-1.15 0-1.925.825-.775.825-.775 1.975 0 1.15.8 1.925.8.775 1.9.775ZM9 40.8h30V30.3H9Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.inspectionReport")}}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
              <router-link to="/payment-failure-report" class="nav-link" v-bind:class="{ active: this.$route.fullPath === '/payment-failure-report' }" data-selenium-name="menu-payment-report">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><path d="M44 11v26q0 1.2-.9 2.1-.9.9-2.1.9H7q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1ZM7 16.45h34V11H7Zm0 6.45V37h34V22.9ZM7 37V11v26Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.paymentFailureReport")}}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="Global.brokerDetails.accountSource !== 'Affiliate' ? '/cancellation-report':'/cancellation-aff-report'" class="nav-link" v-bind:class="{ active: this.$route.path === '/cancellation-report' || this.$route.path === '/cancellation-aff-report' }" data-selenium-name="menu-cancellation-report">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" ><path d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.cancellationReport")}}</span>
              </router-link>
            </li>
            <li class="nav-item" v-bind:class="{'wrapper' : isNullOrEmpty(Global.brokerDetails.licenseNumber)}" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
              <hr class="horizontal dark mt-3">
              <router-link to="/new-quote" class="nav-link" v-bind:class="{ active: this.$route.path === '/new-quote' }" data-selenium-name="menu-get-quote">
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><path d="M23.25 17.35V11.2h-6.2v-3h6.2V2.05h3V8.2h6.15v3h-6.15v6.15ZM14.5 44q-1.5 0-2.55-1.05-1.05-1.05-1.05-2.55 0-1.5 1.05-2.55Q13 36.8 14.5 36.8q1.5 0 2.55 1.05 1.05 1.05 1.05 2.55 0 1.5-1.05 2.55Q16 44 14.5 44Zm20.2 0q-1.5 0-2.55-1.05-1.05-1.05-1.05-2.55 0-1.5 1.05-2.55 1.05-1.05 2.55-1.05 1.5 0 2.55 1.05 1.05 1.05 1.05 2.55 0 1.5-1.05 2.55Q36.2 44 34.7 44ZM14.5 33.65q-2.1 0-3.075-1.7-.975-1.7.025-3.45l3.05-5.55L7 7H3.1V4h5.8l8.5 18.2H32l7.8-14 2.6 1.4-7.65 13.85q-.45.85-1.225 1.3-.775.45-1.825.45h-15l-3.1 5.45h24.7v3Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.newQuote")}}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <hr class="horizontal dark mt-3">
              <a class="nav-link" href="javascript:void(0)" @click="$router.go(0);eventTracking('mainMenu', 'Menu_Logout', 'Portal');"> 
                <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><path d="M22 25.5q.65 0 1.075-.45.425-.45.425-1.05 0-.65-.425-1.075Q22.65 22.5 22 22.5q-.6 0-1.05.425-.45.425-.45 1.075 0 .6.45 1.05.45.45 1.05.45ZM13 42v-3l14-2.45v-23.3q0-.7-.375-1.225-.375-.525-1.025-.625L13 9.45V6.5l13.3 2.3q1.6.25 2.65 1.525Q30 11.6 30 13.2V39Zm-7 0v-3h4V9.5q0-1.3.875-2.15Q11.75 6.5 13 6.5h22q1.3 0 2.15.85.85.85.85 2.15V39h4v3Zm7-3h22V9.5l-22-.05Z"/></svg>
                </div>
                <span class="nav-link-text ms-1">{{$t("menu.logout")}}</span>
              </a>
            </li>
            <li class="nav-item mt-3 d-none" id="smile3section">
              <div class="vdo2section mx-auto text-center w-100">
                <video id="webcam" autoplay playsinline width="250" height="150"></video>
                <button class="btn btn-sm bg-gradient-secondary mx-auto close2vdo" @click="stop2vdo();">{{$t('menu.close')}}</button>
                <img id="smile" class="max-w100 d-none" :src="publicPath + 'img/smile.png'" alt="Just smile" />
              </div>
            </li>
            <li class="nav-item">
              <button class="btn btn-sm btn-round bg-gradient-info mx-auto" type="button" id="hide2menu" @click="hide2menu()" >
                  <i class="fas fa-arrow-left text-sm ms-1" aria-hidden="true"></i> {{$t('menu.hide')}}
                </button>
            </li>
            <li class="nav-item">
              <button class="btn btn-sm btn-round bg-gradient-primary mx-auto d-none" type="button" id="show2menu" @click="show2menu();">
                {{$t('menu.show')}} <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
              </button>
            </li>
          </ul>
        </div>
      </aside>
      <!-- Sidebar -->

      <!-- Mian Content -->
      <main class="main-content  position-relative h-100 mt-1 border-radius-lg " id="main2area">
        <!-- Navbar -->
        <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl bg-white mt-3" id="navbarBlur" v-if="$route.name !== 'SignIn' && Global.jwt !== ''">
          <div class="container-fluid py-1 px-3">
            <nav aria-label="breadcrumb">
            <h6 class="font-weight-bolder mb-0">{{$t("pageTitle")}}</h6>
              <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                <li class="breadcrumb-item text-sm"><router-link to="/home" class="text-dark" :class="{'opacity-5' : this.$route.path !== '/home'}">{{$t("pageName.home")}}</router-link></li>
                <li class="breadcrumb-item text-sm text-dark" aria-current="page" v-for="(b, index) in breadcrumb" v-bind:key="index">
                  <router-link :to="b.href" v-if="b.href !== this.$route.path">{{$t(b.name)}}</router-link>
                  <span v-else>{{$t(b.name)}}</span>
                </li>
              </ol>
            </nav>
            <div class="mt-sm-0 mt-2 me-md-0 me-sm-4 d-flex" id="navbar">
              <div class="ms-md-auto pe-md-3 align-items-center d-flex">
              </div>
              <ul class="navbar-nav  justify-content-end  d-flex">
                <li class="nav-item user align-items-left">
                  <a class="nav-link font-weight-bold px-0 active">
                    <i class="fa fa-user me-sm-1"></i>
                    <span class="d-sm-inline">{{Global.email}}</span>
                  </a>
                </li>
                <li  class="nav-item dropdown px-5 pe-2  align-items-right">
                  <selectLang></selectLang>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <!-- End Navbar -->

        <div class="container-fluid py-4 px-0">
          <router-view />
          <!-- menu2mobile -->
          <div class="modal fade" id="menu2mobile" tabindex="-1" aria-labelledby="menu2mobileLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content p-3">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="w-auto px-3 pb-3">
                    <ul class="navbar-nav">
                      <li class="nav-item">
                        <router-link to="/home" class="nav-link" @click="hideMenuModal()" v-bind:class="{ active: this.$route.path === '/home' }">{{$t("menu.home")}}</router-link>
                      </li>
                      <li class="nav-item" v-bind:class="{'wrapper' : Global.authority === 'ADMIN'}">
                        <router-link :to="Global.brokerDetails.accountSource !== 'Affiliate' ? '/dashboard' : '/dashboard-aff'" class="nav-link" v-bind:class="{ active: this.$route.path === '/dashboard', active: this.$route.path === '/dashboard-aff' }" @click="hideMenuModal()">{{$t("menu.dashboard")}}</router-link>
                      </li>
                      <li class="nav-item mt-3">
                        <h6 class="text-uppercase text-xs font-weight-bolder opacity-6">{{$t("menu.report")}}</h6>
                      </li>
                      <li class="nav-item">
                        <router-link :to="Global.brokerDetails.accountSource !== 'Affiliate' ? '/opportunity-report' : '/opportunity-aff-report'" class="nav-link  ms-2" v-bind:class="{ active: this.$route.path === '/opportunity-report' , active: this.$route.path === '/opportunity-aff-report'}" @click="hideMenuModal()">{{$t("menu.opportunityReport")}}</router-link>
                      </li>
                      <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
                        <router-link to="/active-policy-report" class="nav-link  ms-2" v-bind:class="{ active: this.$route.path === '/active-policy-report' }" @click="hideMenuModal()">{{$t("menu.activepolicyReport")}}</router-link>
                      </li>
                      <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
                        <router-link to="/renewal-report" class="nav-link  ms-2" v-bind:class="{ active: this.$route.path === '/renewal-report' }" @click="hideMenuModal()">{{$t("menu.renewalReport")}}</router-link>
                      </li>
                      <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
                        <router-link to="/inspection-report" class="nav-link  ms-2" v-bind:class="{ active: this.$route.path === '/inspection-report' }" @click="hideMenuModal()">{{$t("menu.inspectionReport")}}</router-link>
                      </li>
                      <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
                        <router-link to="/payment-failure-report" class="nav-link  ms-2" v-bind:class="{ active: this.$route.fullPath === '/payment-failure-report' }" @click="hideMenuModal()">{{$t("menu.paymentFailureReport")}}</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link :to="Global.brokerDetails.accountSource !== 'Affiliate' ? '/cancellation-report' : '/cancellation-aff-report'" class="nav-link  ms-2" v-bind:class="{ active: this.$route.path === '/cancellation-report', active: this.$route.path === '/cancellation-aff-report' }" @click="hideMenuModal()">{{$t("menu.cancellationReport")}}</router-link>
                      </li>
                      <li class="nav-item" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
                        <hr class="horizontal dark mt-3">
                        <router-link to="/new-quote" class="nav-link" v-bind:class="{ active: this.$route.path === '/new-quote' }" @click="hideMenuModal()">{{$t("menu.newQuote")}}</router-link>
                      </li>
                      <li class="nav-item">
                        <hr class="horizontal dark mt-3">
                        <router-link to="/signin" class="nav-link" @click="hideMenuModal();eventTracking('mainMenu', 'Menu_Logout', 'Portal');">{{$t("menu.logout")}}</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary text-white bg-gradient-primary" data-bs-dismiss="modal">{{$t('menu.close')}}</button>
                </div>
              </div>
            </div>
          </div>
          <!-- menu2mobile -->


        </div>

        <!-- Footer -->
        <footer class="footer mt-5" v-bind:class="{'fixedBottom': footerFixed, 'signinBottom': isSignInPage}">
          <div class="container-fluid">
            <div class="row align-items-center justify-content-lg-between">
              <div class="col-12">
                <div class="copyright text-center text-sm text-lg-start" v-bind:class="[footerClass]">
                  ©2016 Roojai.com | Registration no. 0205557039412 | Broker license no. ว00022/2558</div>
                </div>
            </div>
          </div>
        </footer>
        <!-- Footer -->

      </main>
      <!-- Main Content -->


      <div class="fixed-plugin" v-if="$route.name !== 'SignIn' && Global.jwt !== ''">
        <a class="fixed-plugin-button text-white bg-gradient-primary position-fixed px-3 py-2" data-bs-toggle="modal" data-bs-target="#menu2mobile">
          <i class="fa fa-bars py-2" aria-hidden="true" style="font-size: 0.75rem;"> </i> menu
        </a>
      </div>


    </div>

    <div class="modal fade" id="errorModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body px-4">
            <div class="text-center mb-3">
              <svg viewBox="0 0 24 24" width="50" height="50" stroke="#f44336" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mb-3"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
            </div>
            <h6 class="modal-title text-center text-orange" id="titleError">{{Global?.errorModal?.title}}</h6>
            <div id="descError" class="mb-3 text-center text-orange">
              {{Global?.errorModal?.desc}}
            </div>

            <div id="errCodeError" class="mb-3 error-code text-center">
              {{Global?.errorModal?.errCode}}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('menu.close')}}</button>
          </div>
        </div>
      </div>
    </div>

  <!-- Global Popup Message -->
    <div class="modal fade" id="globalPopupModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="global-message-title">
      <div class="modal-dialog modal-dialog-centered modal2detail4pk" role="document">
        <div class="modal-content">
          <div class="modal-header modal-header-info">
            <h4 id="globalPopupModal-header" class="modal-title text-title"></h4>
          </div>
          <div id="globalPopupModal-body" class="modal-body pt-4">
          </div>
          <div class="modal-footer">
            <button id="globalPopupModal-button" data-selenium-name="close-change-plan-btn" type="button" class="btn bg-gradient-primary w-100 " data-bs-dismiss="modal">
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END Popup Message -->

    <div id="topLoading">
      <div class="spinner-container">
        <div class="spinner-border text-info" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div id="pdpa_cookie" data-selenium-name="pdpa_cookie" v-if="isShowPDPA">
      <div id="pdpa_section" class="pdpa_content">
        <i18n-t tag="p" keypath="pdpaTexts" id="pdpa_text" class="pdpa_text">
          <a :href="pdpaRoojaiURL" target="_blank" rel="follow">{{$t('pdpaPrivacyPolicy')}}</a>
        </i18n-t>
        
        <div id="pdpa_btn">
          <button id="pdpa_accept_all" type="button" data-selenium-name="pdpa_accept_all" class="btn btn-sm btn-pdpa-accept" @click="setPdpaCookie()">{{$t('pdpaAccept')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import selectLang from "@/components/SelectLang";

export default {
  mixins: [mixin],
  components: { selectLang },
  data: function() {
    return {
      mainContentElementHeight: 0,
      backdrop:null,
      isShowPDPA: false,
      pdpaName: "Roojai_PDPA"
    };
  },
  methods: {

    show2menu: function() {
			document.getElementById("main2area").classList.remove("hide2pk");
			document.getElementById("sidenav-main").classList.remove("hide2side");
      document.getElementById("show2menu").classList.add("d-none");
			document.getElementById("hide2menu").classList.remove("d-none");
		},
    hideMenuModal:function(){
      if( document.querySelector("#menu2mobile") ){
        var modal = bootstrap.Modal.getInstance(document.querySelector("#menu2mobile"));

        if(modal){
          modal.hide();
        }
      }
    },
    setPdpaCookie: async function() {
      var vm = this;
      var date = Global.serverTime ? new Date(Global.serverTime) : new Date(),
          domain = window.location.hostname.includes('roojai') ? '.' + window.location.hostname.split('.').slice(1).join('.') : window.location.hostname;
      
      date.setYear(date.getFullYear() + 1);
      document.cookie = vm.pdpaName + "=accept; expires=" + date.toGMTString() + "; domain="+ domain +"; path=/";

      var cookieDiv = document.querySelector("#pdpa_cookie"); 

      if(cookieDiv){
        cookieDiv.classList.remove("pdpa_show");
        await new Promise(r => setTimeout(r, 200));
      }
      vm.isShowPDPA = !document.cookie.includes(vm.pdpaName);
      this.submitPdpa();
    },
  },
  watch: {
    "Global.locale": function(value){
        this.$i18n.locale = value;
        Global.customerShortLink = null;
        Global.customerQRCode = null;
    }
  },
  async mounted() {
    var vm = this;

    var body = this._e("body");

    if( this.$route.path == '/' || this.$route.name == 'SignIn' || this.$route.name == 'ResetPassword' ){
      body.classList.remove("bg-pk");
      body.classList.add("bg-gray-100");
    }else{
      body.classList.remove("bg-gray-100");
      body.classList.add("bg-pk");
    }

    window.onresize = function(){
      console.log("onresize");
      if( vm._e("#main2area") ){
        vm.mainContentElementHeight = vm._e("#main2area").offsetHeight;
      }
    }

    this.isShowPDPA = !document.cookie.includes(this.pdpaName);
    if( this.isShowPDPA ){
      await new Promise(r => setTimeout(r, 200));
      var cookieDiv = document.querySelector("#pdpa_cookie"); 
      if(cookieDiv){
        cookieDiv.classList.add("pdpa_show");
      }
    }
  },
  updated(){
    if( this._e("#main2area") ){
      this.mainContentElementHeight = this._e("#main2area").offsetHeight;
    }

    var body = this._e("body");

    if( this.$route.path == '/' || this.$route.name == 'SignIn' || this.$route.name == 'ResetPassword'){
      body.classList.remove("bg-pk");
      body.classList.add("bg-gray-100");
    }else{
      body.classList.remove("bg-gray-100");
      body.classList.add("bg-pk");
    }

  },
  computed: {
    footerFixed:function(){
      var contentHeight = this.mainContentElementHeight + 105;
      return contentHeight < window.outerHeight && this.$route.name !== 'SignIn';
    },
    isSignInPage:function(){
      return this.$route.name == 'SignIn' || this.$route.path == '/' || this.$route.path == '/signin';
    },
    breadcrumb:function(){
      var breadcrumb = [],
          page = {name: null, href: null};
      if(!this.isEmptyString(this.$route.name) && this.$route.name !== "Home"){
        if(this.$route.name == "Dashboard") { page.name = "pageName.dashboard";  page.href = "/dashboard" }
        else if(this.$route.name == "DashboardAffiliate") { page.name = "pageName.dashboard";  page.href = "/dashboard-aff" }
        else if(this.$route.name == "OpportunityReport") { page.name = "opportunityReport"; page.href = "/opportunity-report" }
        else if(this.$route.name == "CancellationReport") { page.name = "cancellationReport"; page.href = "/cancellation-report" }
        else if(this.$route.name == "OpportunityAffiliateReport") { page.name = "opportunityReport"; page.href = "/opportunity-aff-report" }
        else if(this.$route.name == "CancellationAffiliateReport") { page.name = "cancellationAffiliateReport"; page.href = "/cancellation-aff-report" }
        else if(this.$route.name == "RenewalReport") { page.name = "renewalReport"; page.href = "/renewal-report" }
        else if(this.$route.name == "InspectionReport") { page.name = "inspectionReport"; page.href = "/inspection-report" }
        else if(this.$route.name == "PaymentFailureReport") { page.name = "paymentFailureReport"; page.href = "/payment-failure-report" }
        else if(this.$route.name == "ActivePolicyReport") { page.name = "activepolicyReport"; page.href = "/active-policy-report" }
        else if(this.$route.name == "NewQuote") { page.name = "pageName.newQuote"; page.href = "/new-quote" }
        breadcrumb.push(page)
      }

      return breadcrumb
    },
    footerClass:function(){
      return this.$route.path == '/' || this.$route.name == 'SignIn' ? "text-secondary" : "text-white";
    },
    pdpaRoojaiURL: function() {
      if( Global.locale == "en" ){
        return Global.roojaiURL + "/en/terms-and-conditions/privacy-policy/";
      }else if(Global.locale == "zh"){
        return Global.roojaiURL + "/cn/terms-and-conditions/privacy-policy/";
      }else{
        return Global.roojaiURL + "/terms-and-conditions/privacy-policy/";
      }
    },
  },
  beforeUnmount(){
    window.onresize = null;

    if( this.backdrop ){
      this.backdrop.disconnect();
    }
  }
};
</script>
<i18n>
{
  "en":{
    "pdpaTexts":"This website uses cookies to improve performance and collect analytics. By continuing to use the website, you accept our {0}",
    "pdpaPrivacyPolicy":"Privacy Policy",
    "pdpaAccept":"Accept"
  },
  "th":{
    "pdpaTexts":"เว็บไซต์นี้ใช้คุกกี้ในการเก็บข้อมูลเพื่อพัฒนาประสิทธิภาพการใช้งานและเพื่อเก็บข้อมูลสถิติ เมื่อคุณใช้เว็บไซต์ต่อไปถือว่าคุณยอมรับ{0}ของเรา",
    "pdpaPrivacyPolicy":"นโยบายความเป็นส่วนตัว",
    "pdpaAccept":"รับทราบ"
  },
  "zh":{
    "pdpaTexts":"This website uses cookies to improve performance and collect analytics. By continuing to use the website, you accept our {0}",
    "pdpaPrivacyPolicy":"Privacy Policy",
    "pdpaAccept":"Accept"
  }
}
</i18n>