import { createWebHashHistory, createRouter } from "vue-router";
import Global from "@/stores/GlobalVariables.js";

const routes = [
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/signin",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "signin" */ "../views/SignIn.vue")
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
    )
  },
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/Home.vue"
      )
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"
      ),
      beforeEnter: (to, from, next) => {
        if(Global.authority !== 'ADMIN') next();
      }
  },
  {
    path: "/dashboard-aff",
    name: "DashboardAffiliate",
    component: () =>
      import(
        /* webpackChunkName: "dashboard-aff" */ "../views/DashboardAffiliate.vue"
      ),
      beforeEnter: (to, from, next) => {
        if(Global.authority !== 'ADMIN') next();
      }
  },
  {
    path: "/opportunity-report",
    name: "OpportunityReport",
    component: () =>
      import(
        /* webpackChunkName: "opportunity-report" */ "../views/OpportunityReport.vue"
      )
  },
  {
    path: "/opportunity-aff-report",
    name: "OpportunityAffiliateReport",
    component: () =>
      import(
        /* webpackChunkName: "opportunity-aff-report" */ "../views/OpportunityAffiliateReport.vue"
      )
  },
  {
    path: "/cancellation-aff-report",
    name: "CancellationAffiliateReport",
    component: () =>
      import(
        /* webpackChunkName: "cancellation-aff-report" */ "../views/CancellationAffiliateReport.vue"
      )
  },
  {
    path: "/cancellation-report",
    name: "CancellationReport",
    component: () =>
      import(
        /* webpackChunkName: "cancellation-report" */ "../views/CancellationReport.vue"
      )
  },
  {
    path: "/renewal-report",
    name: "RenewalReport",
    component: () =>
      import(
        /* webpackChunkName: "renewal-report" */ "../views/RenewalReport.vue"
      )
  },
  {
    path: "/inspection-report",
    name: "InspectionReport",
    component: () =>
      import(
        /* webpackChunkName: "inspection-report" */ "../views/InspectionReport.vue"
      )
  },
  {
    path: "/payment-failure-report",
    name: "PaymentFailureReport",
    component: () =>
      import(
        /* webpackChunkName: "payment-failure-report" */ "../views/PaymentFailureReport.vue"
      )
  },
  {
    path: "/new-quote",
    name: "NewQuote",
    component: () =>
      import(
        /* webpackChunkName: "new-quote" */ "../views/NewQuote.vue"
      )
  },
  {
    path: "/active-policy-report",
    name: "ActivePolicyReport",
    component: () =>
      import(
        /* webpackChunkName: "opportunity-report" */ "../views/ActivePolicyReport.vue"
      )
  },
  {
    path: "/monitor",
    name: "Monitor",
    component: () =>
      import(
        /* webpackChunkName: "opportunity-report" */ "../views/Monitor.vue"
      )
  },
  {
    path: "/*",
    redirect: "/signin"
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== "SignIn" && to.name !== "About" && to.name !== "ResetPassword" && to.name !== "Monitor" && Global.jwt === "")
    next({
      name: "SignIn"
    });
  else if((to.name === "About" || to.name === "Monitor") && Global.jwt === "") next();
  else next();

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export default router;
