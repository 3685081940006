import { reactive } from "vue";

export default reactive({
  // apiUrl: "https://sf-mcs.uat-roojai.com",
  apiUrl: "https://sf-mcs.roojai.com",
  jwt: "",
  apiUser: {
    username: "apiUser",
    password: "admin",
    jwt: ""
  },
  email: "",
  GAEncodeUrl: {
    agent: "",
    customer: ""
  },
  roojaiURL: "https://roojai.com",
  authority: "",
  njsmcsURL: "https://njsmcs.roojai.com/api",
  mcsURL: "https://mcs.roojai.com/api",
  mcsToken: "",
  njsToken: null,
  customerShortLink: null,
  customerQRCode: null,
  productShortlink: {
    productList: [
      { "product":"allProduct" },
      { "product":"carInsurance" },
      // { "product":"extendedWarranty" },
      { "product":"motorBikeInsurance" },
      { "product":"PAInsurance" },
      { "product":"cancerInsurance" },
      { "product":"CIInsurance" }
    ],
    isError: false,
    selected: ""
  },
  locale: "th",
  uploadDocument: {
    url: "https://dms.roojai.com",
    authenUsername: "filemanageapp",
    authPassword: "PsAcdD3T@uMw",
    isError: true
  },
  myAccount: {
    url: "https://myaccount.roojai.com/api",
    authenUsername: "guestUserProd",
    authPassword: "8cpwKU6Pt4tDNZjSMQfyProd",
    token: "",
    frontend: "https://myaccount.roojai.com/"
  },
  serverTime: null,
  omisePublicKey: "",
  dateFormat: "YYYY-MM-DD",
  dashboard: {
    summaryReport: {
      GWP: null,
      noOfQuote: null,
      opportunityCreated: null,
      outstandingOpportunity: null,
      paymentFailure: null,
      overduePayment: null,
    },
    commission: null,
    policySold: null,
    cancellation: null,
    newPolicy: null,
    renew: null,
    pendingInspection: null,
    webcam: null,
    syncDateTime: null,
    isShowCommission: null,
    referralCommission: null
  },
  dashboardAffiliate: {
    GWP: null,
    quotes: null,
    opportunity: null,
    policySold: null,
    cancellation: null,
    commission: null,
    syncDateTime: null
  },
  searchConditions: {
    dashboard: {
      dateFrom: "",
      dateTo: "",
      dateRange: "thismonth",
    },
    dashboardAffiliate: {
      dateFrom: "",
      dateTo: "",
      dateRange: "thismonth",
    },
    opportunityReport: {
      currentPage: 1,
      dateFrom: "",
      dateTo: "",
      dateType: "createddate",
      productType: "",
      rowsPerPage: 20,
      policyStatus: "Proposal",
      status: "",
      sortKey: "createddate",
      sortDirection: "desc",
      oppNumber: "",
      phoneNumber: "",
      custName: "",
      custEmail: "",
      carPlate: "",
      agentEmail: "",
      isAdvanceSearch: false,
      apiVersion: "3" /*2 current, 3 Revamp */
    },
    opportunityAffiliateReport: {
      currentPage: 1,
      dateFrom: "",
      dateTo: "",
      dateType: "paymentdate",
      productType: "",
      rowsPerPage: 20,
      policyStatus: "",
      status: "",
      sortKey: "paymentdate",
      sortDirection: "desc",
      oppNumber: "",
      phoneNumber: "",
      custName: "",
      custEmail: "",
      carPlate: "",
      agentEmail: "",
      isAdvanceSearch: false,
      apiVersion: "3"
    },
    cancellationAffiliateReport: {
      currentPage: 1,
      dateFrom: "",
      dateTo: "",
      dateType: "cancelleddate",
      productType: "",
      rowsPerPage: 20,
      policyStatus: "",
      status: "",
      sortKey: "cancelleddate",
      sortDirection: "desc",
      oppNumber: "",
      phoneNumber: "",
      custName: "",
      custEmail: "",
      carPlate: "",
      isAdvanceSearch: false,
      apiVersion: "3"
    },
    renewalReport: {
      currentPage: 1,
      dateFrom: "",
      dateTo: "",
      dateType: "renewaldate",
      productType: "",
      rowsPerPage: 20,
      policyStatus: "Proposal",
      sortKey: "renweoppname",
      sortDirection: "desc",
      oppNumber: "",
      phoneNumber: "",
      custName: "",
      custEmail: "",
      carPlate: "",
      agentEmail: "",
      isAdvanceSearch: false,
      apiVersion: "3" /*2 current, 3 Revamp */
    },
    inspectionReport: {
      currentPage: 1,
      dateFrom: "",
      dateTo: "",
      dateType: "inspectiontaskcreateddate",
      productType: "",
      rowsPerPage: 20,
      policyStatus: "",
      sortKey: "inspectiontaskcreateddate",
      sortDirection: "desc",
      oppNumber: "",
      phoneNumber: "",
      custName: "",
      custEmail: "",
      carPlate: "",
      agentEmail: "",
      isAdvanceSearch: false,
      inspectionStatus: ""
    },
    paymentFailureReport: {
      currentPage: 1,
      dateFrom: "",
      dateTo: "",
      dateType: "duedate",
      productType: "",
      rowsPerPage: 20,
      // policyStatus: "",
      status: "Activated",
      sortKey: "duedate",
      sortDirection: "desc",
      oppNumber: "",
      phoneNumber: "",
      custName: "",
      custEmail: "",
      carPlate: "",
      agentEmail: "",
      isAdvanceSearch: false,
      apiVersion: "3", /*2 current, 3 Revamp */
      paymentStatus:""
    },
    activepolicyReport: {
      currentPage: 1,
      dateFrom: "",
      dateTo: "",
      dateType: "activatedate",
      productType: "",
      rowsPerPage: 20,
      policyStatus: "Closed Won",
      status: "",
      sortKey: "activatedate",
      sortDirection: "desc",
      oppNumber: "",
      phoneNumber: "",
      custName: "",
      custEmail: "",
      carPlate: "",
      agentEmail: "",
      transactionType: "",
      isAdvanceSearch: false,
      apiVersion: "3" /*2 current, 3 Revamp */
    },
    cancellationReport: {
      currentPage: 1,
      dateFrom: "",
      dateTo: "",
      dateType: "cancelleddate",
      productType: "",
      rowsPerPage: 20,
      policyStatus: "Closed Won",
      status: "",
      sortKey: "cancelleddate",
      sortDirection: "desc",
      oppNumber: "",
      phoneNumber: "",
      custName: "",
      custEmail: "",
      carPlate: "",
      agentEmail: "",
      isAdvanceSearch: false,
      apiVersion: "3" /*2 current, 3 Revamp */
    },
  },
  brokerDetails: {
    accountSource: null,
    firstname: null,
    lastname: null,
    intermediaryAccountId: null,
    intermediaryReptEmail: null,
    intermediaryReptId: null,
    isPersonAccount: false,
    licenseNumber: null,
    parentAccountId: null,
    phone: null,
    type: null,
    licenseExpDate:null,
    licenseExpRemainDaysAlert:[1,15,30,45],
    bankAccount:null,
    bankName:null,
    address:null,
    gaNumber:null,
    isTbAdmin: false
  },
  retrieveQuoteURL:null,
  termsAndCondition: null,
  disclaimerVersionId: "",
});
