import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import VCalendar from 'v-calendar';

const messages = {
  en: {
    pageTitle: "Roojai Agent Portal",
    opportunityReport: "Opportunity Report",
    renewalReport: "Renewal Report",
    inspectionReport: "Inspection Report",
    paymentFailureReport: "Payment Report",
    cancellationReport: "Cancellation Report",
    opportunityAffiliateReport: "Opportunity Report",
    cancellationAffiliateReport: "Cancellation Report",
    activepolicyReport: "Sales & Policy Report",
    pageName: {
        home: "Home",
        dashboard: "Dashboard",
        newQuote: "Get Quote",
        
    },
    menu: {
        home: "Home",
        dashboard: "Dashboard",
        report: "Report",
        newQuote: "Get Quote",
        opportunityReport: "Opportunity Report",
        renewalReport: "Renewal Report",
        inspectionReport: "Inspection Report",
        paymentFailureReport: "Payment Report",
        cancellationReport: "Cancellation Report",
        cancellationAffiliateReport: "Cancellation Report",
        activepolicyReport: "Sales & Policy Report",
        logout: "Log out",
        download: "Download",
        submit: "Submit",
        show: "Show menu",
        hide: "Hide menu",
        close: "Close",
    },
    serviceNotAvailable: "Oops! Service not available, please contact admin."
  },
  th: {
    pageTitle: "Roojai Agent Portal",
    opportunityReport: "รายงานใบเสนอราคา",
    renewalReport: "รายงานการต่ออายุ",
    inspectionReport: "รายงานการตรวจสภาพ",
    paymentFailureReport: "รายงานการชำระเงิน",
    cancellationReport: "รายงานการยกเลิก",
    opportunityAffiliateReport: "รายงานใบเสนอราคา",
    cancellationAffiliateReport: "รายงานการยกเลิก",
    activepolicyReport: "รายงานการขายกรมธรรม์",
    pageName: {
        home: "หน้าหลัก",
        dashboard: "แดชบอร์ด",
        newQuote: "เช็คราคา",
    },
    menu: {
        home: "หน้าหลัก",
        dashboard: "แดชบอร์ด",
        report: "รายงาน",
        newQuote: "เช็คราคา",
        opportunityReport: "รายงานใบเสนอราคา",
        renewalReport: "รายงานการต่ออายุ",
        inspectionReport: "รายงานการตรวจสภาพ",
        paymentFailureReport: "รายงานการชำระเงิน",
        cancellationReport: "รายงานการยกเลิก",
        opportunityAffiliateReport: "รายงานใบเสนอราคา",
        cancellationAffiliateReport: "รายงานการยกเลิก",
        activepolicyReport: "รายงานการขายกรมธรรม์",
        logout: "ออกจากระบบ",
        download: "ดาวน์โหลด",
        submit: "Submit",
        show: "แสดงข้อมูล",
        hide: "ซ่อนข้อมูล",
        close: "ปิด",
    },
    serviceNotAvailable: "Oops! Service not available, please contact admin."
  },
  zh: {
    pageTitle: "Roojai Agent Portal",
    opportunityReport: "机会报告",
    renewalReport: "续订报告",
    inspectionReport: "验车报告",
    paymentFailureReport: "支付状况报告",
    cancellationReport: "取消保单报告",
    opportunityAffiliateReport: "机会报告",
    cancellationAffiliateReport: "取消保单报告",
    activepolicyReport: "销售&保单报告",
    pageName: {
        home: "主页",
        dashboard: "计量板",
        newQuote: "获取报价",
    },
    menu: {
        home: "主页",
        dashboard: "计量板",
        report: "报告",
        newQuote: "获取报价",
        opportunityReport: "机会报告",
        renewalReport: "续订报告",
        inspectionReport: "验车报告",
        paymentFailureReport: "支付状况报告",
        cancellationReport: "取消保单报告",
        cancellationAffiliateReport: "取消保单报告",
        activepolicyReport: "销售&保单报告",
        logout: "登出",
        download: "下载",
        submit: "提交 ",
        show: "Show menu",
        hide: "Hide menu",
        close: "Close",
    },
    serviceNotAvailable: "Oops! Service not available, please contact admin."
  }
};

const datetimeFormats = {
  en: {
    year:{
      year: 'numeric'
    },
    month:{
      month: 'long'
    },
    shortMonth:{
      month: 'short'
    },
    shortWithTime: {
      year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric', weekday: 'long'
    }
  },
  th: {
    year:{
      year: 'numeric'
    },
    month:{
      month: 'long'
    },
    shortMonth:{
      month: 'short'
    },
    shortWithTime: {
      year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric', weekday: 'long'
    }
  },
  zh: {
    year:{
      year: 'numeric'
    },
    month:{
      month: 'long'
    },
    shortMonth:{
      month: 'short'
    },
    shortWithTime: {
      year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric', weekday: 'long'
    }
  },
}

const i18n = createI18n({
    locale: "th",
    fallbackLocale: "th",
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    messages,
    datetimeFormats
  });

  createApp(App)
    .use(router)
    .use(i18n)
    .use(VCalendar, {})
    .mount('#app')